.profile-notification-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-notification-wrapper .bell-icon {
  margin-top: 7px;
  margin-right: 21px;
}

.header-dropdown-btn .dropdown-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.header-dropdown-btn {
  border: 0;
  color: black;
}

/* .header-dropdown-btn:hover {
  color: black;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
} */

.header-dropdown-btn:focus {
  color: unset;
  box-shadow: unset;
  transition: unset;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

.profile-icon {
  border: 1px solid #005280;
  background: #bddff2;
  color: #005280;
  width: 56px;
  height: 56px;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 27px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-letters {
  font-size: 20px;
  position: relative;
  bottom: 1px;
  text-transform: uppercase;
}

.header-dropdown-btn {
  height: auto;
  padding: 6px 20px;
  border-radius: 4px;
}

.header-dropdown-btn:hover {
  box-shadow: none;
}

.header-dropdown-btn .dropdown-btn-wrapper .dropdown-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-dropdown-btn .dropdown-btn-wrapper .dropdown-btn-text {
  text-align: left;
  line-height: 20px;
  margin-left: 10px;
}

.header-dropdown-btn .dropdown-btn-wrapper .dropdown-btn-text p:last-child {
  font-size: 10px;
  opacity: 0.67;
}

@media (max-width: 992px) {
  .header-dropdown-btn .dropdown-btn-wrapper .dropdown-btn-text {
    display: none;
  }


}