/* @import '~antd/dist/antd.css'; */
@import "~antd/dist/antd.variable.min.css";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

body {
  margin: 0;
  font-family: "Ubuntu";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 0;
}

p.text-field-label {
  font-size: 11px;
}

.Toastify__toast-body {
  font-family: "Ubuntu" !important;
}

button.ant-btn-default {
  font-size: 13px;
}

.ant-dropdown-menu-title-content {
  color: #004992;
  padding: 5px;
  width: 165px;
  font-weight: bold;
  font-size: 12px;
}

.primary-btn {
  background: #2a7ef5;
  width: 100%;
  padding: 12px;
  height: max-content;
  border-radius: 5px;
  font-weight: bold;
  border: none;
}


/* Global styles for RTE */

.draftjs-rte-wrapper {
  border: 1px solid #8d8d8d;
  border-radius: 6px;
  margin-top: 10px;
}

.draftjs-rte-wrapper .rdw-editor-toolbar {
  border-radius: 6px;
}


.rdw-option-wrapper {
  border: none !important;
  outline: none;
  box-shadow: none;
}

.draftjs-rte-wrapper .rdw-editor-main {
  padding: 2px 12px;
  min-height: 100px;
}

.draftjs-rte-wrapper .rdw-editor-toolbar {
  border-bottom: 1px solid #8d8d8d;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 6px;
}

.inline-draft-rte-styles .rdw-option-wrapper {
  padding: 16px 12px;
}

.blocktype-draft-rte-styles .rdw-option-wrapper {
  padding: 16px 12px;
}

.list-draft-rte-styles .rdw-option-wrapper {
  padding: 16px 12px;
}

.align-draft-rte-styles .rdw-option-wrapper {
  padding: 16px 12px;
}



.rdw-option-wrapper:hover {
  background-color: #a3c6f5;
}

.rdw-option-active {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;
  background-color: #a3c6f5 !important;
}