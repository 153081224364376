/* Main layout styles */

.r-admin-sider-layout>.r-admin-sider {
  background: linear-gradient(180deg, #00429a 0%, #000000 100%);
  height: 100vh;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.r-admin-sider-layout ::-webkit-scrollbar {
  width: 2px;
}

.r-admin-sider-layout>.r-admin-sider .ant-menu {
  border: none;
  background: transparent;
}

.r-admin-sider-layout>.r-admin-sider .logo img {
  width: 85px;
  margin-top: 40px;
}

.r-admin-sider-layout>.r-admin-sider .logo {

  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ant-layout-sider-collapsed.r-admin-sider ul li {
  padding-top: 5px !important;
}

.r-admin-sider ul li .ant-menu-title-content {
  font-size: 13px;
}

.r-admin-sider .ant-menu:not(.ant-menu-horizontal) {
  margin-top: 52px;
}

.r-admin-sider ul .ant-menu-item {
  color: #8a92a6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.r-admin-sider ul .ant-menu-item svg {
  fill: #fefefe;
}

.r-admin-sider ul .ant-menu-item svg image {
  filter: invert(100%) sepia(2%) saturate(0) hue-rotate(205deg) brightness(89%) contrast(103%);
}

.r-admin-sider ul li.ant-menu-item-selected svg image {
  filter: invert(2%) sepia(34%) saturate(5546%) hue-rotate(205deg) brightness(89%) contrast(103%)
}

.r-admin-sider ul li.ant-menu-item-selected svg.inrange-icon {
  filter: none;
}

.r-admin-sider ul li.ant-menu-item-selected svg.inrange-icon image {
  filter: none;
}

.r-admin-sider ul .ant-menu-item:not(.ant-menu-item-selected):hover svg.inrange-icon image {
  filter: saturate(100%);
}

.r-admin-sider ul .ant-menu-item:not(.ant-menu-item-selected):hover svg image {
  filter: invert(2%) sepia(34%) saturate(5546%) hue-rotate(205deg) brightness(89%) contrast(103%);
}

.r-admin-sider ul .ant-menu-item:hover {
  background-color: #b8d4ff;
  border-radius: 4px;
  color: #00429a;
}

.r-admin-sider ul .ant-menu-item:not(.ant-menu-item-selected):hover svg {
  filter: invert(72%) sepia(34%) saturate(5546%) hue-rotate(205deg) brightness(89%) contrast(103%) !important;
}

.r-admin-sider .ant-menu:not(.ant-menu-horizontal) li.ant-menu-item-selected {
  background-color: #e7f1fe;
  border-radius: 4px;
  font-weight: bold;
  color: #00429a !important;
  margin: 0 auto;
  margin-bottom: 8px;
}

.r-admin-sider .ant-menu:not(.ant-menu-horizontal) li.ant-menu-item-selected::after {
  display: none;
}

.r-admin-sider ul li.ant-menu-item-selected svg {
  fill: #00429a !important;
  filter: invert(72%) sepia(34%) saturate(5546%) hue-rotate(205deg) brightness(89%) contrast(103%);

}

.postsIcon .r-admin-sider ul li.ant-menu-item-selected svg {
  filter: none;
}

.ant-layout-sider-collapsed.r-admin-sider ul li .ant-menu-item-group-title {
  visibility: hidden;
}



.ant-layout-sider-collapsed.r-admin-sider .logo .smashers-logo {
  width: 80px;

}

.ant-layout-sider-collapsed.r-admin-sider .logo img {
  margin: 0;
}

.r-admin-sider ul li .ant-menu-item-group-title {
  font-size: 12px;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item {
  padding: 0px calc(55% - 13px);
}


/* header design */
.r-admin-layout .r-admin-header {
  background: #fff;
  min-height: 100px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 30px;
  z-index: 10;

}

.quick-access-title {
  font-size: 10px;
  text-transform: capitalize;
  margin-left: 20px;
  font-weight: bold;

}

.quick-create-event-btn {
  color: #004C98;
  background: #CCE6FF;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
  margin-right: 5px;
  margin-left: 20px;
  padding-top: 5px;
  padding-right: 20px;
  height: 40px;
  width: 170px;
  border: 1px solid #2373C5;
  line-height: 17px;
}

.quick-access-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-header {
  line-height: unset;
}

.r-admin-layout .r-admin-header .tiny-blue-btn {}

.r-admin-layout .r-admin-header .collapse-btn {
  left: -12px;
  top: 12px;
}

.r-admin-layout .r-admin-header .tiny-blue-btn svg {
  height: 20px;
  width: 20px;
  fill: #3a57e8;
  border-radius: 6px;
}

.r-admin-layout .r-admin-header .header-search-input {
  width: 320px;
  margin-left: 12px;
  border-radius: 4px;
}

.r-admin-layout .r-admin-header .header-search-input .ant-input-group-addon button {
  border-left: none;
}

/* For lower resolutions media queries */
@media (max-width: 992px) {
  .r-admin-layout .r-admin-header .header-search-input {
    width: 120px;
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  flex-direction: column;
  height: 100%;
  padding-left: 12px !important;
}

.ant-menu-item a {
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}

.ant-menu-item a:first-child {
  color: unset;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}


.site-layout-background {
  margin: 30px 50px;
  margin-top: 60px;
  /* overflow-x: auto; */
}

.ant-menu-item .ant-menu-item-icon+span {
  margin-left: unset;
}

.ant-menu-inline>.ant-menu-item {
  line-height: 18px;
  margin-bottom: 12px;
}

/* .ant-menu-inline .ant-menu-item:not() {
  margin-bottom: 27px;
} */

[ant-click-animating-without-extra-node='true']::after {
  display: none;
}