.statesDropdown li {
  display: block;
}

.statesDropdown .ant-select {
  width: 100%;
}

.deviceSupportRadioBtn .ant-radio-inner {
  border-radius: 2px;
}

.deviceSupportRadioBtn .ant-radio-wrapper {
  margin-right: 70px;
}

.deviceSupportRadioBtn .ant-radio-checked .ant-radio-inner {
  background: #3a57e8;
}

.viewDeviceSupportRadioBtn .ant-radio-inner {
  border-radius: 2px;
}

.viewDeviceSupportRadioBtn .ant-radio-wrapper {
  margin-right: 70px;
}

.viewDeviceSupportRadioBtn .ant-radio-checked .ant-radio-inner {
  background: rgb(67, 66, 66);
}

.topTracerphoneInput {
  margin-top: 20px;
  margin-bottom: 20px;
}

.topTracerphoneInput .PhoneInputInput {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #8d8d8d;
  padding: 10px;
}

.topTracerphoneInput .PhoneInputCountry {
  display: none !important;
}