.create-event-form-section {
    text-align: left;
  }
  
  .heading {
    font-weight: bolder;
    font-size: 24px;
  }
  
  .ant-divider-horizontal.ant-divider-with-text {
    border-top-color: rgba(0, 82, 128, 0.25);
  }
  
  .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left:after {
    margin-bottom: 0;
  }
  
  .create-event-form-section .points-heading {
    font-weight: bolder;
    font-size: 1rem;
  }
  
  .create-event-form-section .payments-heading {
    font-weight: bolder;
    font-size: 1rem;
  }
  
  .create-event-form-section .create-event-col .create-event-form {
    margin-top: 30px;
  }
  
  .create-event-form .ant-form-item-explain-error {
    font-size: 11px;
    margin-top: 4px;
  }
  
  /* Special text field UI fixes */
  .text-field-label {
    margin-bottom: 10px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  .create-event-form-heading.heading-second {
    margin-bottom: 30px;
  }
  
  .form-input-field-custom {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #8d8d8d;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    height: 50px;
  }
  
  .form-icon-field .ant-input {
    padding-left: 6px !important;
  }
  
  .form-textarea-field-custom {
    padding-top: 12px;
    border-radius: 5px;
  }
  
  .form-date-field-custom {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    height: 50px;
    border: 1px solid #8d8d8d;
    cursor: pointer;
  }
  
  .ant-picker-input > input {
    font-size: 14px;
    letter-spacing: 0.04em;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 700;
  }
  
  .ant-picker-suffix,
  .ant-select-arrow {
    width: 12px;
    color: #005280;
  }
  
  .form-select-field-custom .ant-select-selector {
    width: 100%;
    height: 50px !important;
    padding: 12px;
    border-radius: 4px !important;
    border: 1px solid #8d8d8d !important;
  }
  
  textarea {
    resize: none;
  }
  
  textarea.ant-input {
    height: 96px;
    border: 1px solid #8d8d8d;
    padding: 16px 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    font-weight: bold;
    overflow: hidden;
  }
  
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 10px;
  }
  
  .form-select-field-custom .ant-select-selector .ant-select-selection-item {
    /* padding: 12px !important; */
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .create-event-btn {
    background: #2a7ef5;
    width: 240px;
    padding: 12px;
    height: max-content;
    border-radius: 5px;
    font-weight: bold;
    border: none;
  }
  
  .create-event-btn:hover,
  .create-event-btn:focus {
    background: #3b5d86;
    transition: all;
  }
  
  .form-select-field-promo {
    /* padding: 12px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  
  .ant-upload.ant-upload-drag {
    height: 110px;
    border: 1px dashed #000000;
    border-radius: 4px;
    margin-top: 15px;
  }
  
  .ant-upload.ant-upload-drag p.ant-upload-text,
  .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: start;
    margin-bottom: 10px;
  }
  
  .ant-upload.ant-upload-drag p.instructions {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: start;
  }
  
  .poster-text {
    font-size: 11px;
    letter-spacing: 1px;
  }
  
  .upload-container {
    display: flex;
    background: #cce6ff;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 165px;
    height: 34px;
    margin-left: 10px;
    color: #004992;
  }
  
  .upload-container p {
    margin-right: 9px;
    font-size: 10px;
    font-weight: bold;
  }
  
  .ant-table-thead > tr > th {
    background: #ccdff2;
    color: #0432ab;
    font-size: 12px;
    text-align: center;
  }
  
  .delete-btn,
  .delete-btn:hover {
    border: none;
    background: none;
    color: red;
    cursor: pointer;
    font-weight: 100;
  }
  
  .edit-btn,
  .edit-btn:hover {
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 100;
  }
  
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  
  .actions-container {
    display: flex;
  }
  
  .actions-container .ant-btn {
    padding: 0;
    margin-right: 6px;
  }
  
  .actions-container .ant-btn.ant-btn > .anticon + span {
    margin-left: 1px;
  }
  
  .ant-picker-input > input {
    font-size: 14px;
  }
  
  .form-select-field-custom
    .ant-select-selector
    .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  
  .table-columns {
    background: #ccdff2;
    display: flex;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .column {
    color: #0432ab;
    width: 115px;
    display: block;
    text-align: center;
  }
  
  .input-container {
    flex: 1;
  }
  
  .input-container input,
  select {
    padding: 10px 16px;
    width: 100%;
    text-align: center;
    border-radius: 0;
    font-weight: bold;
    border: 1px solid lightslategray;
    height: 44px;
    background: #fff;
  }
  
  .input-container select {
    cursor: pointer;
  }
  
  .rewards-table-wrapper {
    width: 500px;
    margin-bottom: 30px;
  }
  
  .rewards-table-wrapper .rewards-table-row {
    display: flex;
    flex: 1;
  }
  
  .error-wrapper {
    color: #e74c3c;
    font-size: 11px;
    width: 120px;
  }
  
  .ant-image-preview-img {
    width: 300px;
  }
  
  .smashPointsError {
    color: red;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 11px;
  }
  
  .view-rules {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
    margin-top: 10px;
    padding: 20px;
    color: rgba(0, 0, 0, 0.25);
  }
  
  .view-input-container input,
  .view-input-container select {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed !important;
  }
  
  .rowDeleteBtn {
    width: 0px;
    display: flex;
    align-items: center;
    color: red;
  }
  
  .rowDeleteBtn svg {
    margin-left: 12px;
  }
  
  .holesCheckbox {
    border: 0.5px solid #8d8d8d;
    border-radius: 5px;
    padding: 10px;
    width: 40%;
    margin-bottom: 36px;
    margin-top: 18px;
  }
  
  .holesCheckbox .ant-checkbox + span {
    width: 25px;
  }
  
  .holeSelectAll {
    cursor: pointer;
    font-weight: bold;
    padding-left: 4px;
    padding-top: 0;
  }
  
  .holeSelectAll span {
    text-decoration: underline;
  }
  
  .holeSelectAll[disabled],
  .holeSelectAll:hover,
  .holeSelectAll:active,
  .holeSelectAll:focus {
    background: none;
  }
  
  .holeParValCheckbox {
    font-size: 16px;
    font-weight: 200;
    margin-right: 5px;
  }
  
  .holeParValCheckbox .ant-checkbox span {
    border: 1px solid black;
    border-radius: 3px;
    height: 16px;
    width: 16px;
  }
  
  .flights-distr-text {
    text-align: center;
    width: 500px;
    border: 1px solid;
    border-bottom: none;
    padding: 5px;
  }
  
  .scoring-options {
    margin-top: 5px;
    margin-bottom: 10px;
    height: 50px;
    border: 1px solid #8d8d8d;
    border-radius: 4px;
    font-weight: normal;
    cursor: pointer;
  }
  
  .customizeRenderEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    gap: 10px;
    text-align: center;
  }
  
  .customizeRenderEmpty svg {
    stroke: lightgrey;
  }
  
  .ant-tooltip-inner {
    font-size: 12px;
    line-height: 15px;
  }
  