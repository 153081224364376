.community-container .community-heading {
  font-weight: bolder;
  font-size: 24px;

}

.community-container .ant-divider-horizontal.ant-divider-with-text-left:after {
  border-top-color: rgba(0, 82, 128, 0.25);
}

.community-container .community-input {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #8D8D8D;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  height: 50px;
}


.community-container .uppercase {
  text-transform: uppercase;
}

.community-container .text-field-label {
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 1px;
  font-size: 11px;
}


.create-community-form .ant-form-item-explain-error {
  font-size: 11px;
  margin-top: 4px;

}

.ant-upload.ant-upload-drag p.ant-upload-hint {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.preview-img {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 10px;
}

.view-vouchers-table {
  margin-bottom: 25px;
}

.view-vouchers-table .ant-table-tbody>tr>td {
  text-align: center;

}

.counter-input {
  margin-bottom: 30px;
}

.counter-input .ant-input-number-input {
  height: 48px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
}

.counter-input button,
.counter-input button:hover {
  height: 50px;
  background: #023D8D;
  color: #fff;
  font-size: 25px;
  width: 50px;
}

.percentage-error {
  color: #e74c3c;
  text-align: end;
  font-size: 11px;
}

.total-percentage {
  text-align: end;
  color: #004992;
  font-size: 18px;
}