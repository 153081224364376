.holes-top-container {
  display: flex;
  width: 580px;
  justify-content: space-between;
}

.holes-top-container .holes-top-container-left {
  text-align: center;
  background: #CCE5FF;
  padding: 4px 11px;
  font-size: 12px;
  color: #0432AB;
}

.holes-top-container .holes-top-container-right {
  display: flex;
}

.holes-top-container .holes-top-container-right span {
  border-right: 2px solid black;
  padding-right: 4px;
  padding-left: 4px;
  margin-bottom: 6px;
  font-size: 12px;
}

.holes-top-container .holes-top-container-right span:last-child {
  border-right: none;
}

.holes-container {
  border: 1px solid #000000;
  border-radius: 5px;
  border-top-left-radius: 0;
  width: 580px;
  margin-bottom: 18px;
}

.holes-input,
.holes-number {
  display: flex;
}

.holes-input .out-hole {


  border-left: 1px solid #000000;
  margin-left: 10px;
}

.holes-input .out-hole input {
  color: #000000;
  font-weight: bold;
}

.holes-input {
  border-top: 1px solid #000000;
}

.holes-number {
  border-bottom: 1px solid #000000;
}

.holes-input input,
.holes-number span {
  width: 35px;
  height: 35px;
  text-align: center;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}

.holes-number span {
  background: #CCE5FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #0432AB;
}


.holes-input p,
.holes-number p {
  border-right: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}